import './jquery.js'
import Splitting from "splitting";

/*
    Summary
        0 - Const / Variables
        1 - Functions
        2 - Button - ChangeBanner
*/
/*---------------------/ 0 - Const / Variables /-------------------*/
Splitting()
var isMobile = window.screen.width < 599 ? true : false
var slides = document.querySelectorAll('.slide');
var controls = document.querySelectorAll('.slide__navigation .btn--nav');
var slidingAT = isMobile ? 1000 : 2300; // sync this with scss variable
var slidingBlocked = false;
var slideActive = 1;

/*---------------------/ 1 - Functions /-------------------*/
function animationLetters(slide) {
    const text = slide.querySelectorAll('.slide__content .char')

    gsap.fromTo(
        text,
        0.7,
        {
            transformOrigin: "center",
            rotationY: 90,
            x: 30
        },
        {
            delay: isMobile ? 0.6 : 0,
            rotationY: 0.1,
            x: 0,
            stagger: 0.025,
        })
}
function newControlClickHandler(button) {
    slidingBlocked = true
    controls.forEach(control => {
        control.classList.remove("active")
    })

    const $control = button;
    const $indexControl = $control.dataset.index
    $control.classList.add("active")
    if ($indexControl != slideActive) {
        slides.forEach(slide => {
            slide.classList.remove("s--active")
            slide.classList.remove("s--prev")
            slide.classList.remove("s--next")
        })

        slides.forEach((slide, index) => {
            const indexSlider = slide.dataset.index
            if (indexSlider == $indexControl) {
                animationLetters(slide)
                slide.classList.add("s--active")
                $(`.btn--nav[data-index=${indexSlider}]`).addClass('active');
                slideActive = indexSlider

                if (indexSlider == 1) {
                    slides[slides.length - 1].classList.add("s--next")
                    slides[index + 1].classList.add("s--next")


                } else if (index == slides.length - 1) {
                    slides[0].classList.add("s--prev")
                    slides[index - 1].classList.add("s--prev")


                } else {
                    slides[index + 1].classList.add("s--next")
                    slides[index - 1].classList.add("s--prev")

                }
            }
        })
    }

    setTimeout(() => {
        slidingBlocked = false
    }, 4000)

}

/*---------------------/ 2 - Button - ChangeBanner /-------------------*/
controls.forEach(function ($el) {
    if (slidingBlocked == false) {
        $el.addEventListener('click', () => {
            newControlClickHandler($el)
        })
    };
});

gsap.fromTo(
    '.char',
    0.7,
    {
        transformOrigin: "center",
        rotationY: 90,
        x: 30
    },
    {
        delay: isMobile ? 0.6 : 0,
        rotationY: 0.1,
        x: 0,
        stagger: 0.025,
        opacity: 1,
    });

// Function to handle autoplay
let autoplayInterval;

function startAutoplay() {
    autoplayInterval = setInterval(() => {
        const nextIndex = slideActive < slides.length ? parseInt(slideActive) + 1 : 1;
        const nextControl = document.querySelector(`[data-index="${nextIndex}"]`);
        newControlClickHandler(nextControl);
    }, 6000); // Change the interval time as needed (4 seconds in this example)
}

function pauseAutoplay() {
    clearInterval(autoplayInterval);
}

function resumeAutoplay() {
    startAutoplay();
}

// Add event listeners to pause and resume autoplay on mouseover and mouseout
slides.forEach((slide) => {
    slide.addEventListener('mouseover', pauseAutoplay);
    slide.addEventListener('mouseout', resumeAutoplay);
});

// Start autoplay when the page loads
startAutoplay();

